import { createAsyncThunk } from '@reduxjs/toolkit'
import { SUBMIT_CODE, submitCodeApi } from './api/submitCode'
import { AppDispatch } from 'store'
import { RootState } from 'types/redux'
import { JudgeRequestBody, JudgeResponseBody } from '@/types'

export type SubmitCodePayload = {
  problemId: string
  language: number
  code: string
  breakpoints?: Set<number>
  extraFiles?: Map<string, string>
}

type ArgType = SubmitCodePayload

const submitCode = createAsyncThunk<JudgeResponseBody & { problemId: string }, ArgType, { dispatch: AppDispatch, state: RootState }>(
  SUBMIT_CODE,
  async (args: ArgType, thunkApi) => {
    const { problemId, language, code, breakpoints, extraFiles } = args
    const systemTests = thunkApi.getState().testSuite.systemTests
    const customTests = thunkApi.getState().testSuite.customTests

    const shapedPayload: JudgeRequestBody = {
      problemId,
      language,
      code,
      extra: extraFiles,
      meta: {
        systemTests: systemTests?.map((t) => t.systemId),
        customTests: customTests
          .filter((t) => !t.isReadOnly)
          .map((t) => ({ input: t.input.join('\n') })),
        breakpoints: [...breakpoints].sort((a, b) => a - b),
      },
    }

    const res = await submitCodeApi(shapedPayload)
    return { ...res as JudgeResponseBody, problemId }
  }
)

export default submitCode
