import { ProblemMeta, ProblemType } from "@/types";

export function getRawVerdict(verdict: string): number {
  switch (verdict) {
    case "AC": return 0;
    case "WA": return 1;
    case "MLE": return 2;
    case "TLE": return 3;
    case "OLE": return 4;
    case "RE": return 5;
    case "CE": return 6;
    case "SE": return 7;
    case "VE": return 8;
  }

  return null;
}

export function determineProblemType(meta: ProblemMeta): ProblemType {
  return meta?.params?.length === 1 && meta.params[0] === "input" ?
    ProblemType.CLASS :
    ProblemType.FUNCTION
}
