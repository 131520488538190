import { axiosInstance } from '@/utils/fetch'
import { JudgeRequestBody, JudgeResponseBody } from '@/types'

const SUBMIT_CODE = '/judge'

const submitCode = async (payload: JudgeRequestBody) => {
  const res = await axiosInstance().post<JudgeResponseBody>(SUBMIT_CODE, payload)
  return res.data
}

export { SUBMIT_CODE, submitCode as submitCodeApi }
