import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import jwtDecode from 'jwt-decode'
import submitCode from '../thunks/submitCode'
import { logOut } from '../sharedActions'
import { Submission } from "@/middleware/types"
import { getRawVerdict } from '@/utils/functions'
import { RootState } from '../../types/redux'
import { JWTPayload } from '@/types'

const initialUser: RootState['user'] = {
  token: null,
  expiresAt: null,
  refreshToken: null,
  userInfo: null,
  completion: {},
  submissions: {}
}

export const user = createSlice({
  name: 'user',
  initialState: initialUser,
  reducers: {
    setAuthToken: (state, action: PayloadAction<{ accessToken: string, refreshToken: string }>) => {
      try {
        const { avatar, sub: userId, name, exp: expiresAt }: JWTPayload = jwtDecode(action.payload.accessToken)
        state.token = action.payload.accessToken
        state.expiresAt = expiresAt
        state.refreshToken = action.payload.refreshToken

        // TODO: Update backend to return plans and products
        state.userInfo = { avatar, id: userId, name, plans: [], products: [] }
      } catch (err) {
        state.token = null
        state.userInfo = null
      }
    },
    setCompletion: (state, action: PayloadAction<{ [key: string]: boolean }>) => {
      state.completion = action.payload
    },
    setSubmissionsForProblem: (state, action: PayloadAction<{ id: string, submissions: Submission[] }>) => {
      const { id, submissions } = action.payload
      state.submissions[id] = submissions
    },
    setProblemAsComplete: (state, action: PayloadAction<string>) => {
      state.completion[action.payload] = true
    },
    addSubmission: (state, action: PayloadAction<{ id: string, submission: Submission }>) => {
      const { id, submission } = action.payload
      state.submissions[id].push(submission)
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(logOut, () => {
        return {
          token: null,
          expiresAt: null,
          refreshToken: null,
          userInfo: null,
          completion: {},
          submissions: {}
        }
      })
      .addCase(submitCode.fulfilled, (state, action) => {
        const problemId = action.payload.problemId
        const verdict = getRawVerdict(action.payload.global_verdict.verdict)

        if (state.completion[problemId] || verdict !== 0) {
          return state;
        }

        state.completion[problemId] = true
      })
  }
})

export default user.reducer
export const {
  setAuthToken,
  setCompletion,
  setSubmissionsForProblem,
  setProblemAsComplete,
  addSubmission
} = user.actions
