import { AxiosInstance } from "axios"
import { MutableRefObject } from "react"
import { Submission, User } from "@/middleware/types"
import { editor as mEditor } from "monaco-editor";
import { Props as ProfileModalProps } from "@/components/ProfileModal";
import { Props as LoginModalProps } from "@/components/LoginModal";
import { Overwrite } from "@/utils/types";

export type AllProblems = {
  questions: RootProblemMeta[];
  articles: RootArticleMeta[];
}

export type RootArticleMeta = {
  id: string;
  title: string;
}

export type StatsFile = {
  freq: { [key: string]: number }
  list: string[]
}

export type RootProblemMeta = {
  id: string;
  type?: string;
  title: string;
  difficulty: number;
  difficultyLabel?: string;
  tags: string[];
  companies: string[];
}

export interface ArticleMeta {
  title: string;
  content: ArticleBlock[];
}

export interface ArticleBlock {
  left?: ArticleBlockContent;
  right?: ArticleBlockContent
}

export type ArticleBlockContent = {
  type: "markdown" | "code";
  content: string | string[];
}

export interface ProblemMeta {
  title: string;
  statement: string;
  constraints: string;
  sample_tests?: SampleTestCase[];
  editorials?: ProblemEditorial[];
  default_code?: string[];
  params?: ProblemParam[];
  interface?: ProblemInterface;
  checker?: ProblemChecker;
}

export interface ProblemInterface {
  user?: ProblemUserInterface[];
}

export interface ProblemUserInterface {
  name?: string;
  methods?: ProblemMethod[];
  params?: ProblemParam[];
}

export interface ProblemMethod {
  name?: string;
  type?: string;
  params?: ProblemParam[];
}

export interface ProblemParam {
  name?: string;
  type?: string;
  visualize?: ProblemVisualizeType;
}

export enum ProblemVisualizeType {
  ADJACENCY_LIST = "adjacency-list",
  ADJACENCY_MATRIX = "adjacency-matrix",
  BINARY_TREE = "binary-tree",
  EDGE_LIST = "edge-list",
  DIRECTED_ADJACENCY_LIST = "directed-adjacency-list",
  DIRECTED_ADJACENCY_MATRIX = "directed-adjacency-matrix",
  DIRECTED_EDGE_LIST = "directed-edge-list",
  LINKED_LIST = "linked-list",
}

export interface ProblemChecker {
  type?: string;
  entrypoint?: string;
  shortcuts?: Map<string, string>;
}

export interface SampleTestCase {
  input: string;
  output: string;
  explanation?: string;
}

export interface ProblemEditorial {
  title: string;
  editorials: { [key: string]: string };
}

export enum ProblemType {
  FUNCTION,
  CLASS
}

export type LanguageOption = {
  value: string;
  label: string;
  icon: string;
  rawValue: number;
}

export interface LocalSubmission {
  code: string;
  timestamp: number;
}

interface UserInfo {
  _id: string;
  avatar: string;
}

export interface AuthState {
  token?: string;
  userInfo?: UserInfo;
  expiresAt?: number;
  isAuthenticated: boolean;
  didRequest: boolean;
}

export interface FetchContextProps {
  authAxios: AxiosInstance;
}

export interface TestCase {
  input: string[];
  expected?: string[];
  output?: string[];
  stdout?: string[];
  stderr?: string[];
  status?: number;
  time?: number;
  memory?: number;
  sampleId?: number;
  systemId?: number;
  customId?: number;
  isReadOnly?: boolean;
}

export interface TestSuiteGlobalVerdict {
  verdict: number;
  time?: number;
  memory?: number;
}

export interface TestSuiteContextProps {
  globalVerdict?: TestSuiteGlobalVerdict;
  sampleTests: TestCase[];
  systemTests: TestCase[];
  customTests: TestCase[];
  nextCustomID: number;
  addCustomTestCase: () => void;
  updateCustomTestCase: (id: number, input: string[]) => void;
  deleteTestCase: (customId?: number, systemId?: number) => void;
  updateTests: (globalVerdict: TestSuiteGlobalVerdict, sample: JudgeResult[], system: JudgeResult[], custom: JudgeResult[]) => void;
  isSubmitting: boolean;
  submitCode: (problemId: string, language: number, code: string, breakpoints?: Set<number>, extraFiles?: Map<string, string>) => Promise<readonly [number, number]>;
  hasSubmitError: boolean;
  didChangeProblemMidRequest: MutableRefObject<boolean>;
}

export interface ProblemMetaState {
  problemId: string;
  problemMeta: ProblemMeta;
  problemType: ProblemType;
  allProblems: AllProblems;
  definitions: { [key: string]: string };
}

export interface KeybindState {
  submitCode: () => Promise<void>;
}

export interface UserInfoState {
  user?: User;
}

export interface UserSubmissionState {
  submissions?: Submission[];
  completionStatus?: { [key: string]: boolean };
  submissionsMutate: (data?: any, shouldRevalidate?: boolean) => Promise<any | undefined>;
  progressMutate: (data?: any, shouldRevalidate?: boolean) => Promise<any | undefined>;
}

interface SubmissionData {
  code: string,
  extraFiles: Map<string, string>
}

export type onProfileModalOpenProps = Overwrite<ProfileModalProps, {
  isOpen?: boolean;
  onClose?: () => void;
}>;

export type onLoginModalOpenProps = Overwrite<LoginModalProps, {
  isOpen?: boolean;
  onClose?: () => void;
}>

export interface HeaderContextProps {
  onProfileModalOpen: (props?: onProfileModalOpenProps) => void;
  onLoginModalOpen: (props?: onLoginModalOpenProps) => void;
  onProblemDrawerOpen: () => void;
}

export interface EditorContextProps {
  valueGetter: MutableRefObject<() => string>;
  breakpoints: Set<number>;
  isEditorReady: boolean;
  setIsEditorReady: (isEditorReady: boolean) => void;
  currentPage: string;
  setCurrentPage: (currentPage: string) => void;
  editor: MutableRefObject<mEditor.IStandaloneCodeEditor>;
  replaceEditorContent: (newContent: string) => void;
  language: number;
  setLanguage: (language: number) => void;
  switchPage: (page: string, with_language: number) => void;
  editorModels: MutableRefObject<Map<string, mEditor.ITextModel>>;
  resetCurrentPage: () => void;
  getDataForSubmission: (pid: string, lang: number) => SubmissionData;
  editorPage: MutableRefObject<string>;
}

export interface SubmitFeedbackRequest {
  problemId: string;
  category: string;
  message: string;
}

export interface SubmitFeedbackResponse {
  category: string;
  createdAt: number;
  ip: string[];
  message: string;
  problemId: string;
  _id: string;
}

export interface FormatCodeRequest {
  language: number;
  code: string;
}

export interface FormatCodeResponse {
  code?: string;
  error?: string;
}

export interface RefreshTokenResponse {
  accessToken: string;
  refreshToken: string;
}

export interface StripeCheckoutSessionResponse {
  id: string;
  payment_status: string;
}

export interface JudgeRequestBody {
  problemId: string;
  language: number;
  code: string;
  meta?: JudgeCodeMeta;
  extra?: Map<string, string>
}

export interface JudgeCodeMeta {
  breakpoints?: number[];
  systemTests?: number[];
  customTests?: { input: string }[];
}

export interface JudgeResponseBody {
  sample_tests: JudgeResult[];
  system_tests?: JudgeResult[];
  custom_tests?: JudgeResult[];
  global_verdict: GlobalVerdict
}

export interface JudgeResult {
  id?: number;
  input?: string;
  output?: string;
  expected?: string;
  runtime_limit?: number;
  memory_limit?: number;
  returncode?: number;
  stdout?: string;
  stderr?: string;
  time?: number;
  memory?: number;
  verdict?: string;
}

export interface GlobalVerdict {
  verdict: string;
  time?: number;
  memory?: number;
}

export interface SubmissionsData {
  problemId: string;
  language: number;
  code: string;
  didPassSample: boolean;
  verdict: number;
  results: number[];
  runTime: number;
  memoryUsage: number;
  extraFiles?: {
    [key: string]: string
  };
}

export interface UserSubmissionsResponse {
  isComplete: boolean;
  submissions: Submission[];
}

export interface JudgeStatus {
  status: string;
  id: number
}

export interface JWTPayload {
  aud: string;
  avatar: string;
  exp: number;
  iat: number;
  iss: string;
  name: string;
  sub: string;
}
